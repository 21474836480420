import type {Auth} from '@/models/Auth'
import type {ModelResponseDTO} from '@/models/Response'
import {request} from './generic.service'

const guestLogin = async (data: {
  email: string
  password: string
}): Promise<ModelResponseDTO<Auth.Response>> =>
  await request({url: 'auth/login', method: 'post', data})

const updateLogin = async (
  id: string
): Promise<ModelResponseDTO<Auth.Response>> =>
  await request({url: `auth/update/${id}`, method: 'get'})

const ssoLogin = (data: {
  token: string
}): Promise<ModelResponseDTO<Auth.Response>> =>
  request({url: 'auth/sso-login', method: 'post', data})

export {guestLogin, ssoLogin, updateLogin}
