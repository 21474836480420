const removeNullValues = (obj: {[key: string]: any}): {[key: string]: any} => {
  const result: {[key: string]: any} = {}

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null) {
      result[key] = obj[key]
    }
  }

  return result
}

export default removeNullValues
