import type {Account} from '@/models/Account'
import type {Alert} from '@/models/Alert'
import {Role} from '@/models/Role'
import mainOidc from '@/oidc-client'
import router from '@/router'
import {guestLogin, ssoLogin, updateLogin} from '@/services/auth.service'
import {StorageSerializers, useStorage} from '@vueuse/core'
import {acceptHMRUpdate, defineStore} from 'pinia'

interface State {
  isLoading: boolean
  currentAccount: any
  authMessage: Alert
  token: any
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    isLoading: false,
    currentAccount: useStorage<Account.Full>('user', null, localStorage, {
      serializer: StorageSerializers.object,
    }),
    authMessage: {
      color: 'success',
      message: '',
    },
    token: useStorage<string>('token', null),
  }),
  getters: {
    loggedIn: (state: State) => state.token !== null,

    isAdmin: (state: State) => state.currentAccount?.role === Role.Type.ADMIN,

    getUserInfo: (state: State) => state.currentAccount as Account.Full,
  },
  actions: {
    async login(data: {email: string; password: string}) {
      try {
        this.isLoading = true
        const res = await guestLogin(data)

        if (res?.success) {
          this.token = res.model.access_token
          this.currentAccount = res.model.account
          if (res.model.account.role === Role.Type.GUEST)
            router.push('/reservation')
          if (res.model.account.role === Role.Type.ADMIN) router.push('/detail')
        }
        this.isLoading = false
      } catch (err: any) {
        this.authMessage.color = 'danger'
        this.authMessage.message = err?.message
        this.isLoading = false
      }
    },

    async onUpdateLogin(id: string) {
      try {
        this.isLoading = true
        const res = await updateLogin(id)

        if (res.success) {
          this.token = res.model.access_token
          this.currentAccount = res.model.account
        }
        this.isLoading = false
      } catch (err: any) {
        this.authMessage.color = 'danger'
        this.authMessage.message = err
        this.isLoading = false
      }
    },

    async isSSOLogin(token: string) {
      try {
        this.isLoading = true
        const response = await ssoLogin({token})

        if (response.success) {
          this.token = response.model.access_token
          this.currentAccount = response.model.account
          if (response.model.account.role === Role.Type.GUEST)
            router.push('/reservation')
          if (response.model.account.role === Role.Type.ADMIN)
            router.push('/detail')
        }
        this.isLoading = false
      } catch (err: any) {
        this.authMessage.color = 'danger'
        this.authMessage.message = err.message
        this.isLoading = false
      }
    },

    logout() {
      if (mainOidc.isAuthenticated) mainOidc.signOut()
      this.$reset()
      this.token = null
      this.currentAccount = null
      router.push('/')
    },

    clearMessage() {
      this.authMessage.color = 'success'
      this.authMessage.message = ''
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
