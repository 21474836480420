import {createOidcAuth, LogLevel, SignInType} from 'vue-oidc-client/vue3'
import type {User} from 'oidc-client'

const appUrl = `${import.meta.env.VITE_APP_PROTOCOL}://${
  import.meta.env.VITE_APP_HOSTNAME
}:${import.meta.env.VITE_APP_PORT}${import.meta.env.VITE_APP_BASE}/`

const mainOidc = createOidcAuth(
  'main',
  SignInType.Window,
  appUrl,
  {
    authority: import.meta.env.VITE_APP_CTU_OIDC_AUTHORITY,
    client_id: import.meta.env.VITE_APP_CTU_OIDC_CLIENT_ID,
    response_type: import.meta.env.VITE_APP_CTU_OIDC_RESPONSE_TYPE,
    scope: import.meta.env.VITE_APP_CTU_OIDC_SCOPE,
    post_logout_redirect_uri: appUrl,
  },
  console,
  LogLevel.None
)

// handle events
mainOidc.events.addAccessTokenExpiring(function () {
  // eslint-disable-next-line no-console
  console.log('access token expiring')
})

mainOidc.events.addAccessTokenExpired(function () {
  // eslint-disable-next-line no-console
  console.log('access token expired')
})

mainOidc.events.addSilentRenewError(function (err) {
  // eslint-disable-next-line no-console
  console.error('silent renew error', err)
})

mainOidc.events.addUserLoaded(function (user: User) {
  // eslint-disable-next-line no-console
  console.log('user loaded', user)
})

mainOidc.events.addUserUnloaded(function () {
  // eslint-disable-next-line no-console
  console.log('user unloaded')
})

mainOidc.events.addUserSignedOut(function () {
  // eslint-disable-next-line no-console
  console.log('user signed out')
})

mainOidc.events.addUserSessionChanged(function () {
  // eslint-disable-next-line no-console
  console.log('user session changed')
})

export default mainOidc
