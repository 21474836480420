export namespace Role {
  export interface Model {
    id: number
    type: string
  }

  export enum Type {
    ADMIN = 'ADMIN',
    GUEST = 'GUEST',
  }

  export const TypeMap = new Map<Type, string>([
    [Type.ADMIN, 'Admin'],
    [Type.GUEST, 'Guest'],
  ])
}
