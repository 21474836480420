<template>
  <v-app-bar color="primary">
    <template #prepend>
      <v-tooltip :text="$t('open_presentation')">
        <template v-slot:activator="{props}">
          <v-btn
            href="https://temesvar.fel.cvut.cz/"
            icon="mdi-information-outline"
            variant="text"
            v-bind="props"
          ></v-btn>
        </template>
      </v-tooltip>
    </template>
    <v-app-bar-title>
      <router-link to="/reservation">
        <img src="@/assets/logo_FEL.svg" alt="" width="190" />
      </router-link>
    </v-app-bar-title>
    <v-toolbar-items v-if="authStore.loggedIn" class="d-none d-lg-flex">
      <template v-for="item in menuItems" :key="item.id">
        <v-btn
          v-if="!item.isAdmin || item.isAdmin === authStore.isAdmin"
          :to="item.path"
          variant="text"
          color="white"
        >
          <v-icon left dark>{{ item.icon }}</v-icon>
          {{ $t(item.title) }}
        </v-btn>
      </template>
      <v-btn variant="text" color="white" @click="authStore.logout()">
        <v-icon left dark>{{ 'mdi-exit-to-app' }}</v-icon>
        {{ $t('logout') }}
      </v-btn>
    </v-toolbar-items>
    <template v-slot:append>
      <v-menu>
        <template v-slot:activator="{props}">
          <v-btn variant="text" icon="mdi-earth" v-bind="props"></v-btn>
        </template>
        <v-list class="mt-1">
          <v-list-item
            v-for="locale in $i18n.availableLocales"
            :key="`locale-${locale}`"
            color="primary"
            @click="$i18n.locale = locale"
            :active="locale === $i18n.locale"
          >
            <v-list-item-title
              v-text="locale === 'cs' ? 'CZ' : locale.toUpperCase()"
              class="p-2"
            ></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-app-bar-nav-icon
        v-if="authStore.loggedIn"
        class="d-flex d-lg-none"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </template>
  </v-app-bar>

  <v-navigation-drawer
    class="d-flex d-lg-none"
    v-if="authStore.loggedIn"
    v-model="drawer"
    theme="dark"
    location="top"
    temporary
    permanent
  >
    <v-list nav>
      <template v-for="item in menuItems" :key="item.id">
        <v-list-item
          v-if="!item.isAdmin || item.isAdmin === authStore.isAdmin"
          :prepend-icon="item.icon"
          :title="$t(item.title)"
          :value="item.title"
          :to="item.path"
          @click.stop="drawer = false"
        ></v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn
          block
          variant="elevated"
          prepend-icon="mdi-exit-to-app"
          color="red-darken-3"
          @click="authStore.logout()"
        >
          {{ $t('logout') }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import {useAuthStore} from '@/stores/auth'
import {onMounted, ref, watch} from 'vue'
import {useI18n} from 'vue-i18n'

const authStore = useAuthStore()
const {locale} = useI18n()

const drawer = ref(false)
const menuItems = [
  {
    id: 1,
    title: 'home',
    path: '/reservation',
    icon: 'mdi-home',
    isAdmin: false,
  },
  {
    id: 2,
    title: 'my_profile',
    path: '/profile',
    icon: 'mdi-account',
    isAdmin: false,
  },
  {
    id: 3,
    title: 'admin_panel',
    path: '/detail',
    icon: 'mdi-table-headers-eye',
    isAdmin: true,
  },
]

watch(locale, (newLocale) => {
  localStorage.setItem('locale', newLocale)
})

onMounted(() => {
  if (localStorage.getItem('locale')) {
    locale.value = localStorage.getItem('locale') as string
  } else {
    localStorage.setItem('locale', locale.value)
  }
})
</script>

<style scoped></style>
