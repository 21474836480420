import {useAuthStore} from '@/stores/auth'
import removeNullValues from '@/utils/removeNullValues'
import axios, {type AxiosRequestConfig} from 'axios'

export const request = async ({
  url,
  method,
  data,
  params,
}: AxiosRequestConfig) => {
  const authStore = useAuthStore()
  try {
    const token = localStorage.getItem('token')
    const http = axios.create({
      baseURL: `${import.meta.env.VITE_BE_PROTOCOL}://${
        import.meta.env.VITE_BE_HOSTNAME
      }:${import.meta.env.VITE_BE_PORT}${import.meta.env.VITE_BE_BASE}/`,
    })

    http.defaults.headers.common['Authorization'] = token
      ? `Bearer ${token}`
      : ''

    const clearParams = removeNullValues(params)
    const searchParams = new URLSearchParams(clearParams).toString()

    // @ts-ignore
    const response = await http[method](
      params ? `${url}?${searchParams}` : `${url}`,
      data
    )

    return response.data
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      authStore.logout()
    }

    throw error.response?.data
  }
}
