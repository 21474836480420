import mainOidc from '@/oidc-client'
import {useAuthStore} from '@/stores/auth'
import {START_LOCATION, createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'main',
      component: () => import('../layouts/MainLayout.vue'),
      meta: {
        auth: false,
        isAdmin: false,
      },
      children: [
        {
          path: '/',
          name: 'login',
          component: () => import('../views/LoginPage.vue'),
          meta: {
            auth: false,
          },
        },
        {
          path: '/user-info/:id',
          name: 'user-info',
          component: () => import('../views/UserInfoView.vue'),
          meta: {
            auth: false,
          },
        },
        {
          path: '/reservation',
          name: 'home',
          component: () => import('../views/HomeView.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('../views/ProfileView.vue'),
          meta: {
            auth: true,
          },
        },
        {
          path: '/gdpr',
          name: 'gdpr',
          component: () => import('../views/GDPRView.vue'),
          meta: {
            auth: false,
          },
        },
      ],
    },

    {
      path: '/',
      name: 'admin',
      component: () => import('../layouts/AdminLayout.vue'),
      meta: {
        auth: true,
        isAdmin: true,
      },
      children: [
        {
          path: '/detail',
          name: 'detail',
          component: () => import('../views/admin/DetailCalendar.vue'),
        },
        {
          path: '/booking',
          name: 'booking',
          component: () => import('../views/admin/BookingView.vue'),
        },
        {
          path: '/booking/:id',
          name: 'booking-detail',
          component: () => import('../views/admin/BookingDetail.vue'),
        },
        {
          path: '/calendar',
          name: 'calendar',
          component: () => import('../views/admin/CalendarView.vue'),
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('../views/admin/AccountView.vue'),
        },
        {
          path: '/rooms',
          name: 'rooms',
          component: () => import('../views/admin/RoomsView.vue'),
        },
        {
          path: '/block',
          name: 'block',
          component: () => import('../views/admin/BlockView.vue'),
        },
        {
          path: '/chart',
          name: 'chart',
          component: () => import('../views/admin/ChartView.vue'),
        },
      ],
    },

    {
      path: '/:catchAll(.*)',
      component: () => import('../views/LoginPage.vue'),
      name: 'not found',
    },
  ],
})

mainOidc.useRouter(router)

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()

  if (to.meta.auth && !authStore.loggedIn) {
    next(START_LOCATION)
  } else if (to.meta.isAdmin && !authStore.isAdmin) {
    next(false)
  } else {
    next()
  }
})

export default router
