import messages from '@intlify/unplugin-vue-i18n/messages'
import {createPinia, type PiniaPluginContext} from 'pinia'
import {Calendar, DatePicker, setupCalendar} from 'v-calendar'
import {createApp} from 'vue'
import {createI18n} from 'vue-i18n'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import {loadFonts} from './plugins/webfontloader'
import router from './router'

import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'

// main styles
import 'v-calendar/style.css'
import './assets/scss/main.scss'

// Import all of Bootstrap's JS
import 'bootstrap/dist/js/bootstrap.js'

//fontawesome
import mainOidc from '@/oidc-client'
import {library} from '@fortawesome/fontawesome-svg-core'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
// import * as Sentry from '@sentry/vue'

const app = createApp(App)

library.add(far, fas)

loadFonts()

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'cs',
  fallbackLocale: 'en',
  availableLocales: ['en', 'cs'],
  messages,
})

const appUrl = `${import.meta.env.VITE_APP_PROTOCOL}://${
  import.meta.env.VITE_APP_HOSTNAME
}:${import.meta.env.VITE_APP_PORT}`

const pinia = createPinia()
pinia.use((context: PiniaPluginContext) => {
  context.store.t = i18n.global.t
})

mainOidc.startup().then((ok) => {
  if (ok) {
    // Sentry.init({
    //   app,
    //   dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    //   integrations: [
    //     Sentry.browserTracingIntegration(),
    //     Sentry.replayIntegration(),
    //   ],
    //   // Performance Monitoring
    //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
    //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ['localhost', appUrl],
    //   // Session Replay
    //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // })

    app
      .component('font-awesome-icon', FontAwesomeIcon)
      .component('EasyDataTable', Vue3EasyDataTable)
      .component('Calendar', Calendar)
      .component('DatePicker', DatePicker)
      .use(setupCalendar, {})
      .use(pinia)
      .use(router)
      .use(vuetify)
      .use(i18n)
      .mount('#app')
  } else {
    console.log('Startup Failed!')
  }
})
