// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Vuetify
import {createVuetify, type ThemeDefinition} from 'vuetify'

const colorLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#0065bd',
    'primary-dark': '#005097',
    'primary-light': '#6aade4',
    indigo: '#2c3e50',

    // black
    black: '#181818',
    'black-soft': '#222222',
    'black-mute': '#282828',

    //white
    white: '#ffffff',
    'white-soft': '#f8f8f8',
    'white-mute': '#f2f2f2',

    //warn
    warning: '#ffc107',
    danger: '#db0c0c',
  },
}

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  components: {
    ...components,
  },
  directives,
  theme: {
    defaultTheme: 'colorLightTheme',
    themes: {
      colorLightTheme,
    },
  },
})
// export default createVuetify({
//   // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
//   components,
//   directives,
//   theme: false,
// })
